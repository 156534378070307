<template>
   <div v-if="showModal" class="modals-user-partner" :id="modalId">
      <div class="modal-user-partner-mask modal-user-partner-overlay">
         <div class="modal-user-partner-wrapper">
            <div class="modal-user-partner-container">
               <div class="modal-user-partner-header">
                  <h3>{{ $t('add_user') }}</h3>
                  <br/>
                  <span class="font-italic text-sm">{{ $t('you_are_reg_a_new_user_of_the_type') }} <b>{{ $t('partner') }}</b>!</span>
                  <button class="close text-danger" @click="cancelAction">&times;</button>
               </div>
               <div class="modal-user-partner-body">

                  <div class="row">
                     <div class="col-12">
                        <div class="form-group mb-4 mt-4">
                           <label for="exampleUserName">{{ $t('name') }}*</label>
                           <input v-model="v$.user.displayName.$model" type="text" class="form-control" placeholder="Nome">
                           <div v-if="v$.user.displayName.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.user.displayName.$errors) }}</span>
                           </div>
                        </div>
                     </div>

                     <div class="col-12">
                        <div class="form-group mb-4">
                           <label for="exampleUserEmail">E-mail*</label>
                           <input v-model="v$.user.email.$model"  type="text" class="form-control" placeholder="E-mail">
                           <div v-if="v$.user.email.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.user.email.$errors) }}</span>
                           </div>
                        </div>
                     </div>

                     <div class="col-12">
                        <div class="form-group mb-4">
                           <label for="exampleUserPass">{{ $t('temporary_password') }}*</label>
                           <input v-model="v$.user.password.$model" type="password" class="form-control" placeholder="Senha">
                           <div v-if="v$.user.password.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.user.password.$errors) }}</span>
                           </div>
                           <span
                              class="ml-1"
                              style="font-size: 10px; color: grey; font-style: italic"
                           >
                              {{ $t('this_should_be_a_temporary_password_to_change_it_the_user_simply_has_to_go_to_forgot_password_to_change_the_password') }}
                           </span>
                        </div>
                     </div>
                  </div>

               </div>
               <div class="modal-footer">
                  <button
                     type="button"
                     class="btn btn-outline-secondary btn-sm mx-4 mt-2 mb-3 text-bold"
                     data-dismiss="modal"
                     @click="cancelAction"
                  >
                     {{ $t('cancel') }}
                  </button>

                  <button type="button" @click="confirmAction" :disabled="loading" class="btn btn-outline-success btn-sm mt-2 mb-3 text-bold">
                     {{ $t('create') }}
                     <div v-if="loading" class="spinner-border spinner-border-sm float-right ml-1" role="status" aria-hidden="true"></div>
                  </button>

               </div>
            </div>
         </div>
      </div>
   </div>
</template>

 <script>
 import {email, minLength, required} from "@vuelidate/validators";
 import {useVuelidate} from "@vuelidate/core";
 import validationMixin from "@/mixins/validationMixin";
 import userService from "@/modules/dashboard/modules/user/services/user-service";

export default {
   name: 'AddPartnerUserModal',
   props: {
      modalId: {
         type: String,
         required: true,
      },
      showModal: {
         type: Boolean,
         default: false,
      }
   },
   setup() {
      return { v$: useVuelidate() }
   },
   mixins: [
      validationMixin
   ],
   data() {
      return {
         user: {
            displayName: null,
            email: null,
            password: null,
            profile: 'partner',
            isActive: true
         },
         loading: false,
      };
   },
   validations () {
      return {
         user: {
            displayName: {
               required,
               minLength: minLength(3)
            },
            email: {
               required,
               email
            },
            password: {
               required,
               minLength: minLength(6)
            }
         }
      }
   },
   mounted() {
      this.loading = false;
   },
   beforeDestroy() {
      this.loading = false;
   },
   methods: {
      async confirmAction() {
         this.loading = true;

         this.v$.$touch();
         if (this.v$.$error) {
            this.loading = false;
            return
         }
         // create user here
         await userService.create(this.user);

         this.resetUser();
         this.v$.$reset();

         this.loading = false;
         this.$emit("actionConfirmed");
      },
      cancelAction() {
         this.loading = false;
         this.resetUser();
         this.v$.$reset();
         this.$emit("actionCancelled");
      },
      resetUser () {
         this.user = {
            displayName: null,
            email: null,
            password: null,
            profile: 'partner',
            isActive: true
         }
      }
   }
};
</script>

<style scoped>

.form-group {
   display: flex;
   flex-direction: column; /* Coloca o label acima do input */
   align-items: flex-start; /* Alinha o label à esquerda */
   margin-bottom: 16px;
}

.small-text{
   font-size: 11px;
}

.modals-user-partner {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   text-align: center;
   margin-top: 60px;
}

.modal-user-partner-mask {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10000;
}

.modal-user-partner-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 10000;
}

.modal-user-partner-wrapper {
   box-sizing: border-box;
}

.modal-user-partner-container {
   width: 650px;
   padding: 20px;
   background: white;
   border-radius: 5px;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   box-sizing: border-box;
}

.modal-user-partner-header {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #e5e5e5;
   padding-bottom: 10px;
}

.modal-user-partner-header h3 {
   margin: 0;
}

.modal-user-partner-header .close {
   position: absolute;
   margin-left: 550px;
   border: none;
   background: none;
   font-size: 1.5em;
   cursor: pointer;
   color: #d9124c;
}

.modal-user-partner-body {
   max-height: 60vh;
   overflow-y: auto;
   margin-bottom: 15px;
}
</style>
