<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('coupons') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('coupons') }}</li>
                     <li v-if="isEditing" class="breadcrumb-item active">{{ $t('edit_coupon') }}</li>
                     <li v-else class="breadcrumb-item active">{{ $t('create_coupon') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-12">
                  <div class="card card-outline card-info">

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else>
                        <!-- card-header -->
                        <div class="card-header">
                           <h3 class="card-title">
                              {{ isEditing === false ? $t('create_coupon') : $t('edit_coupon') }}
                           </h3>
                        </div>

                        <form @submit.prevent="save">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-4">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('name') }}*</label>
                                       <input type="text" v-model="coupon.name" class="form-control" placeholder="JOSEFARMACIA">
                                       <span
                                          class="ml-1"
                                          style="font-size: 11px; color: grey; font-style: italic">
                                             {{ $t('only_include_letters_in_register_of_the_coupon_name_the_percentage_will_be_included_in_the_name_automatically') }}
                                       </span>
                                    </div>
                                 </div>

                                 <div class="col-3">
                                    <div class="form-group">
                                       <label>{{ $t('percentage') }}*</label>
                                       <select v-model="coupon.planUuid" class="form-control">
                                          <option disabled>{{ $t('choose_the_percentage') }}*</option>
                                          <option v-for="plan in plans" :value="plan.uuid" >
                                              R$ {{ plan.price }} = {{ plan.percent }} %
                                          </option>
                                       </select>
                                    </div>
                                 </div>

                                 <div class="col-5">
                                    <div class="form-group">
                                       <label>{{ $t('partner') }}*</label>
                                       <div class="d-flex">
                                          <select v-model="coupon.partner" class="form-control select-group">
                                             <option disabled>{{ $t('choose_the_partner') }}*</option>
                                             <option v-for="partner in partners" :value="partner.id" >
                                                {{ partner.name }}
                                             </option>
                                          </select>
                                          <span class="input-group-prepend">
                                          <button
                                             @click.prevent="showAddPartnerModal"
                                             type="button" class="btn btn-info btn-flat" style="border-radius: 5px;">
                                             {{ $t('add') }} </button>
                                       </span>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-4">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('partner_percentage') }}* %</label>
                                       <input type="number" v-model="coupon.partnerPercentage" class="form-control" placeholder="5">
                                       <span
                                          class="ml-1"
                                          style="font-size: 11px; color: grey; font-style: italic">
                                             {{ $t('percentage_amount_that_will_be_passed_on_to_the_partner') }}
                                             {{ $t('just_include_the_number_you_do_not_need_to_enter_the_percent_symbol') }}
                                       </span>
                                    </div>
                                 </div>

                                 <div class="col-3">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('activation_date') }}*</label>
                                       <input type="datetime-local" v-model="coupon.activationDate" class="form-control">
                                       <span
                                          class="ml-1"
                                          style="font-size: 11px; color: grey; font-style: italic">
                                        {{ $t('no_user_were_able_to_use_the_coupon_before_the_activation_date') }}
                                       </span>
                                    </div>
                                 </div>

                                 <div class="col-3">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('expiry_date') }}*</label>
                                       <input type="datetime-local" v-model="coupon.expiryDate" class="form-control">
                                       <span
                                          class="ml-1"
                                          style="font-size: 11px; color: grey; font-style: italic">
                                            {{ $t('after_the_expiration_date_no_new_users_were_able_to_use_the_coupon') }}
                                       </span>
                                    </div>
                                 </div>

                                 <div class="col-2 mt-3">
                                    <div class="form-group">
                                       <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                          <input type="checkbox" v-model="coupon.isActive" class="custom-control-input" id="customSwitchActive">
                                          <label class="custom-control-label" for="customSwitchActive">
                                             {{ coupon.isActive == true ? $t('active') : $t('disabled') }}
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-4 mt-3">
                                    <div class="form-group">
                                       <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                          <input type="checkbox" v-model="coupon.haveUsageLimit" class="custom-control-input" id="customSwitchUsageLimit">
                                          <label class="custom-control-label" for="customSwitchUsageLimit">
                                             {{ $t('use_limit') }}
                                          </label>
                                       </div>
                                    </div>

                                    <div>
                                       <div v-if="coupon.haveUsageLimit">
                                          <div class="form-group mb-4">
                                             <label for="exampleInputEmail1">Quantidade</label>
                                             <input
                                                 type="number"
                                                 v-model="coupon.limit"
                                                 class="form-control" placeholder="Poderá ser usado X vezes"
                                             >
                                                 <span
                                                     class="ml-1"
                                                     style="font-size: 11px; color: grey; font-style: italic">
                                                       {{ $t('only_the') + ' ' + coupon.limit + ' ' + $t('firsts_were_able_to_use_the_coupon') }}
                                                 </span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                              <!-- ./row -->
                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton
                                    :buttonName="saveButtonName"
                                    :isSaving="isSaving"
                                 >
                                 </SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>

         <AddPartnerUserModal
            :modal-id="modalConfirmationActionId"
            :show-modal="showModal"
            @actionConfirmed="showToastUserCreation"
            @actionCancelled="closeModal"
         />

      </section>
   </div>
</template>

<script>
import couponService from "../../../services/coupon-service";
import planService from "../../../services/plan-service";
import userService from "../../user/services/user-service";
import SaveButton from "../../../components/SaveButton.vue";
import { v4 as uuidV4 } from "uuid";
import { useToast } from "vue-toastification";
import AddPartnerUserModal from "@/modules/dashboard/components/AddPartnerUserModal.vue";
import ConfirmationModal from "@/modules/dashboard/components/ConfirmationModal.vue";

export default {
   name: "CouponsForm",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      ConfirmationModal,
      AddPartnerUserModal,
      SaveButton
   },
   data () {
      return {
         coupon: {
            uuid: uuidV4(),
            name: null,
            partnerPercentage: 0,
            activationDateTimestamp: '',
            activationDate: '',
            partner: null,
            planUuid: null,
            expiryDate: '',
            expiryDateTimestamp: '',
            haveUsageLimit: false,
            limit: 0,
            isActive: true
         },
         plans: [],
         partners: [],
         plan: null,
         isLoading: false,
         isEditing: false,
         isSaving: false,
         errors: [],
         showModal: false,
         modalConfirmationActionId: "modal-add-partner-coupon",
      }
   },
   computed: {
      saveButtonName () {
         if (this.isEditing)
            return 'edit';
         else
            return 'save';
      }
   },
   async created() {
      this.isLoading = true;

      try {
         if (this.$route.params.uuid){
            this.isEditing = true;

            const { data: response } = await couponService.get(this.$route.params.uuid);
            this.coupon = response;
            this.coupon.partner = response.userId;
         }

         this.partners = await userService.getAllPartners();

         const { data: plans } = await planService.index();
         this.plans = plans.data;
      } finally {
         this.isLoading = false;
      }
   },
   methods: {
      async save() {
         this.errors = [];

         if (!this.partnerIsActive(this.coupon.partner)) {
            this.toast.warning(this.$t("chosen_partner_is_deactivated_so_cannot_be_associated_with_this_coupon"));
            return;
         }

         const activationDataStr = this.coupon.activationDate;
         const activationDataObj = new Date(activationDataStr);
         this.coupon.activationDateTimestamp = activationDataObj.getTime() / 1000;

         const expiryDataStr = this.coupon.expiryDate;
         const expiryDataObj = new Date(expiryDataStr);
         this.coupon.expiryDateTimestamp = expiryDataObj.getTime() / 1000;

         this.plan = this.plans.find(item => item.uuid === this.coupon.planUuid)
         if (this.plan)
            this.coupon.planUuid = this.plan.uuid;
         else
            return;

         const couponName = this.coupon.name.replace(/\d/g, "");

         if (this.coupon.name)
            this.coupon.name = couponName.toUpperCase() + parseInt(this.plan.percent);
         try {
            this.isSaving = true;
            if (this.isEditing){
               if (!await couponService.edit(this.coupon, this.coupon.uuid)) {
                  this.toast.warning(this.$t("this_coupon_name_already_exists"));
                  return;
               }
               this.toast.success(this.$t("updated"));
            }
            else {
               if (!await couponService.create(this.coupon)) {
                  this.toast.warning(this.$t("this_coupon_name_already_exists"));
                  return;
               }
               this.toast.success(this.$t("saved"));
            }
            this.$router.push({ name: 'coupons' });
         } catch (error) {
            const { status } = error.response;
            const errorResponse = error.response.data.errors;

            if (this.isEditing)
               this.toast.error(this.$t("update_failure"));
            else
               this.toast.error(this.$t("save_failure"));

            if (status === 422){
               this.errors = Object.assign(this.errors, errorResponse)
               Object.keys(this.errors).forEach((field) => {
                  this.errors[field].forEach((error) => {
                     this.toast.error(error);
                  });
               });
            }
         }
         finally {
            this.isSaving = false;
         }
      },
      async showToastUserCreation() {
         this.partners = await userService.getAllPartners();
         this.toast.success(this.$t("partner_user_successfully_included"));
         this.closeModal();
      },
      closeModal() {
         this.showModal = false;
      },
      showAddPartnerModal() {
         this.showModal = true;
      },
      partnerIsActive(partnerUuid) {
         let isActive = true;
         this.partners.forEach((partner) => {
            if (partner.id == partnerUuid)
               isActive = partner.isActive;
         });

         return isActive;
      }
   }
}
</script>

<style scoped>

</style>


